@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fira+Code:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background: #1b1b1b;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.wrapper {
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 15px;
}
.mainContainer {
  padding: 15px;
  padding-left: calc(339px + 15px);
}
.mainWrapper {
  padding-top: 75px;
  max-width: 1185px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.homeWrapper {
  max-width: 1205px;
  width: 90%;
  margin: 0 auto;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s;
}
button:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
@media only screen and (max-width: 1199px) {
  .mainContainer {
    padding: 15px;
  }
}
@media only screen and (max-width: 520px) {
  .mainContainer {
    padding: 15px 10px;
  }
  .mainWrapper {
    padding-top: 125px;
  }
}
.slick-dots {
  bottom: -40px !important;
}
.slick-dots li {
  width: 10px !important;
}
.slick-dots li button {
  transform: none;
  top: 40px;
}
.slick-dots li button:before {
  color: #fff !important;
  font-size: 10px !important;
}
.slick-dots li.slick-active button:before {
  color: #ddbc43 !important;
}
