.wrapper {
  padding: 50px 0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
}
.image {
  width: 100%;
  max-width: 525px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobileHeader {
  display: none;
}
.tagline {
  color: #ddbc43;
  text-align: center;
  font-family: Asap;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.title {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.mobileTitle {
  display: none;
}
.mobileTagline {
  display: none;
}
.line {
  width: 54px;
  height: 4px;
  background: #ddbc43;
}
.info {
  color: #fff;
  font-family: Open Sans;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  padding: 18px 0;
}
.button {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.25%;
  border-radius: 30px;
  background: #ddbc43;
  padding: 15px 24px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .textContainer {
    align-items: center;
  }
  .header {
    display: none;
  }
  .mobileHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tagline {
    text-align: center;
  }
  .title {
    text-align: center;
  }
  .info {
    padding-top: 0;
    padding-bottom: 20px;
    text-align: center;
  }
  .image {
    max-width: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .title {
    font-size: 36px;
  }
  .info {
    font-size: 16px;
  }
  .image {
    max-width: 300px;
  }
  .button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 520px) {
  .image {
    max-width: 240px;
  }
  .title {
    font-size: 32px;
  }
  .info {
    font-size: 14px;
  }

  .button {
    font-size: 13px;
  }
}
