.wrapper {
  border-radius: 4px;
  border: 1px solid #39393c;
  background: #2a2a2a;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  padding: 35px;
  max-width: 480px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}

.title {
  color: #fafafa;
  font-size: 23px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.48px;
  padding-bottom: 15px;
  text-align: center;
}

.text {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form {
  display: flex;
  flex-direction: column;
}

.rememberOrForgot {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}

.termsAndCondition {
  justify-content: flex-start;
  gap: 4px;
}

.forgot {
  color: #FCD435;
  font-size: 11.6px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 13.6px;
  padding-top: 15px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

.button {
  text-align: center;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #2a2a2a;
  background: #FCD435;
  width: 100%;
}

.suggetionContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.suggestionText {
  color: #a1a1a1;
  text-align: center;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.toggle {
  text-align: center;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  color: #1861c8;
  white-space: nowrap;
}

.termsAndCondition {
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  color: #1861c8;
  padding-top: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    top: 50px;
    padding: 18px 12px;
    gap: 18px;
  }
}

@media only screen and (max-width: 399px) {
  .termsAndCondition {
    font-size: 11px;
  }

  .termsAndCondition {
    padding-top: 10px;
  }
}